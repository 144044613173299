<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CNavbar light color="light" class="px-0">
          <h4>All School</h4>
          <CForm inline> </CForm>
          <CForm inline>
            <!-- <CInput class="mr-0 mb-0" placeholder="Search" size="sm" />
            <CButton color="dark" size="sm" class="border-0">
              <CIcon name="cil-search" size="sm" />
            </CButton>&nbsp;&nbsp; -->
          </CForm>
        </CNavbar>

        <b-row>
          <b-col sm="4">
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col offset-sm="5" sm="3">
            Total Records : {{ totalRows }}&nbsp;&nbsp;&nbsp;
            <CButton
              color="outline-dark "
              class="my-2 my-sm-0 btn-out-dashed"
              type="submit"
              size="sm"
              @click="new_school()"
              >+ Add New</CButton
            >
          </b-col> </b-row
        ><br />

        <div
          class="table-responsive"
          style="font-size:14px;font-family: Arial, Helvetica, sans-serif;"
        >
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="xs"
            show-empty
            small
            @filtered="onFiltered"
          >
            <template #cell(actions)="row">
              <b-button
                variant="success"
                class="btn btn-xs btn-primary"
                size="sm"
                @click="info(row.item, row.index, $event.target)"
              >
                Edit
              </b-button>
            </template>
          </b-table>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-9">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
          <div class="col-sm-3 float-right">
            <b-form-group
              horizontal
              label="Per page"
              label-size="sm"
              :label-cols="6"
            >
              <b-form-select
                :options="pageOptions"
                v-model="perPage"
                size="sm"
              />
            </b-form-group>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AddSchool from "./AddSchool";
import axios from "axios";
import apis from "@/apis";
const base_url = process.env.VUE_APP_BASE_URL;

export default {
  name: "School",
  data: function() {
    return {
      items: [],
      fields: [
        // { key: 'id', label: 'Id', sortable: true},
        { key: "school_name", label: "School", sortable: true },
        { key: "address", label: "Address", sortable: true },
        { key: "contact_person", label: "Contact Person", sortable: true },
        { key: "contact_no", label: "Contact No", sortable: true },
        { key: "actions", label: "Action" },
      ],
      fields1: [
        // { key: 'id', label: 'Id', sortable: true},
        { key: "school_name", label: "School", sortable: true },
        { key: "address", label: "Address", sortable: true },
        { key: "contact_person", label: "Contact Person", sortable: true },
        { key: "contact_no", label: "Contact No", sortable: true },
        //  { key: 'actions', label: 'Action' }
      ],
      user_role_id: "",
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      activePage: 1,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    info(row) {
      console.log(row);
      this.$router.push({
        name: "AddSchool",
        params: {
          items: row,
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    new_school() {
      this.$router.push({ name: "AddSchool" });
      //  this.$router.push({ name: "addSchool",params:{
      //      items: row
      //  }});
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    get_school() {
      this.isLoading = true;
      var promise = apis
        .list_Schools()
        .then((response) => {
          this.isLoading = false;
          this.items = response.data;
          this.totalRows = this.items.length;
        })
        .catch((error) => {});
    },
  },
  created() {
    this.user_role_id = localStorage.getItem("user_role_id");
    console.log(this.user_role_id);
    this.get_school();
  },
  components: { AddSchool },
};
</script>
